import React, { useEffect, useState } from 'react';
import classes
    from "../SavingsOrInvestmentsSelector/SavingsOrInvestmentsSelectorYou/SavingsOrInvestmentsSelectorYou.module.scss";
import Checkbox from "../../../UI/Checkbox/Checkbox";
import {
    updateSourceOfIncomeSpouseData,
} from "../../../../store/actions/SourceOfIncomeActions";
import { connect } from "react-redux";

const OtherSourcesSelectorSpouse = ( props ) => {

    const checkboxes = [
        {
            label: `CPP`,
            name: 'cppSpouse'
        },
        {
            label: `QPP`,
            name: 'qppSpouse'
        },
        {
            label: `OAS Pension`,
            name: 'oasPensionSpouse'
        },
        {
            label: `Employer Pension`,
            name: 'employerPensionSpouse'
        },
        {
            label: `Other`,
            name: 'otherSpouse'
        }
    ]

    const initState = {
        checkedItems: new Map(),
    }

    const [checked, setChecked] = useState(initState)

    const changeValHandler = (e) => {

        const item = e.target.name;
        const isChecked = e.target.checked;
        if(item === 'cppSpouse' && isChecked) {
            checked.checkedItems.set('qppSpouse', false)
        }

        if(item === 'qppSpouse' && isChecked) {
            checked.checkedItems.set('cppSpouse', false)
        }

        setChecked({ checkedItems: checked.checkedItems.set(item, isChecked) });
        props.updateSourceOfIncomeSpouseData(checked.checkedItems);
        props.onChange()

    }

    useEffect(() => {
        if (props.spouse.cpp.exist) {
            checked.checkedItems.set('cppSpouse', true)
        }

        if (props.spouse.qpp.exist) {
            checked.checkedItems.set('qppSpouse', true)
        }

        if (props.spouse.oasPension.exist) {
            checked.checkedItems.set('oasPensionSpouse', true)
        }

        if (props.spouse.employerPension.exist) {
            checked.checkedItems.set('employerPensionSpouse', true)
        }

        if (props.spouse.other.exist) {
            checked.checkedItems.set('otherSpouse', true)
        }

        setChecked({ checkedItems: checked.checkedItems })

    },[checked.checkedItems, props])

    return (
        <div className={classes.CheckboxesWrap}>
            {
                checkboxes.map((input, index) => {
                    return (
                        <Checkbox
                            key={index}
                            onChange={changeValHandler}
                            label={input.label}
                            name={input.name}
                            checked={ checked.checkedItems.get(input.name) }
                        />
                    )
                })
            }
        </div>
    )
};

function mapStateToProps(state) {
    return {
        spouse: state.sourceOfIncome.spouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateSourceOfIncomeSpouseData : checkedItems => dispatch(updateSourceOfIncomeSpouseData(checkedItems))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherSourcesSelectorSpouse);