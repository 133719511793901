import React, { useCallback, useEffect, useState } from 'react';
import Layout from "../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../components/Navigation/Navigation";
import { connect } from "react-redux";
import OtherSourcesSelectorYou
    from "../../../components/ContentRow/Step3/OtherSourcesSelector/OtherSourcesSelectorYou";
import OtherSourcesSelectorSpouse
    from "../../../components/ContentRow/Step3/OtherSourcesSelector/OtherSourcesSelectorSpouse";
import { fourthStepCompleted } from "../../../store/actions/SourceOfIncomeActions";
import { retirementCalcLinks } from "../../../Routes/RoutesConst";

const OtherSourcesSelector = ( props ) => {

    const titles = {
        layoutTitle: 'YOUR SOURCES OF INCOME',
        sectionTitle: 'Do you have any other sources of retirement income?',
    }

    const title = "Sources of income for retirement - CPP, QPP, pension & other"

    const [next, setNext] = useState(retirementCalcLinks.sourceOfIncomeSummary)

    const nextLink = useCallback(() => {
        if(props.sourceOfIncomeYou.cpp.exist || props.sourceOfIncomeSpouse.cpp.exist){
            return retirementCalcLinks.cppAmount;
        }else if(props.sourceOfIncomeYou.qpp.exist || props.sourceOfIncomeSpouse.qpp.exist) {
            return retirementCalcLinks.qppAmount;
        }else if(props.sourceOfIncomeYou.oasPension.exist || props.sourceOfIncomeSpouse.oasPension.exist) {
            return retirementCalcLinks.oasPension;
        }else if(props.sourceOfIncomeYou.employerPension.exist || props.sourceOfIncomeSpouse.employerPension.exist){
            return retirementCalcLinks.employerPensionAmount;
        }else if(props.sourceOfIncomeYou.other.exist || props.sourceOfIncomeSpouse.other.exist){
            return retirementCalcLinks.otherAmount;
        }else {
            return retirementCalcLinks.sourceOfIncomeSummary;
        }
    }, [props]);

    useEffect(() => {
        props.fourthStepCompleted(false)
        setNext(nextLink())
    }, [props, nextLink]);

    const onChange = () => {
        setNext(nextLink())
    };

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep4={(1/6)*100}
            active={'step4'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent='otherSourcesSelector'
                sectionTitle={titles.sectionTitle}
            >

                <InnerLayoutStepContent type={'you'}>
                    <OtherSourcesSelectorYou
                        onChange={onChange}
                    />
                </InnerLayoutStepContent>

                {
                    props.isSpouse &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <OtherSourcesSelectorSpouse
                            onChange={onChange}
                        />
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 prev='/source-of-income'
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        sourceOfIncomeYou: state.sourceOfIncome.you,
        sourceOfIncomeSpouse: state.sourceOfIncome.spouse,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        fourthStepCompleted: (val) => dispatch(fourthStepCompleted(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OtherSourcesSelector);