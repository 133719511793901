import React from 'react';
import classes from './Checkbox.module.scss'
import PropTypes from 'prop-types';

const Checkbox = ( { name, checked = false, onChange, label } ) => {
    return (
        <label className = {classes.container}>{label}
          <input
              onChange={onChange}
              type = "checkbox"
              name={name}
              checked={checked}
              value={label}
          />
          <span className = {classes.checkmark} />
        </label>
    )
};

Checkbox.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
}

export default Checkbox;